import { useEffect, useState } from "react";
import styles from "./ChangeLanguage.module.css";
import { useTranslation } from "react-i18next";
import { Button } from "../Button/Button";
import { useUserData } from "../../../context/UserProvider";
import { getData } from "../../../api";
import axiosInstance from "../../../utils/axiosInstance";

export const ChangeLanguage = ({ className }) => {
  const { i18n } = useTranslation();
  const {userData} = useUserData()
  const [lang,setLang] = useState("")
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("lang") ? localStorage.getItem("lang") : i18n.language);
  const { t } = useTranslation();
  const languageOptions = [
    { label: t("Русский"), value: "ru" },
    { label: t("Английский"), value: "en" },
  ];

  useEffect(() => {
    const fetchMe = async () => {
      const {data} = await axiosInstance({
        url: "/me",
        method: "post",
      });
      console.log(data)
      if(data){
        i18n.changeLanguage(data.language);
        localStorage.setItem("lang", data.language)
        setLang(data.language)
      }
    }

    fetchMe()
  }, [])

  const handleChange = (e) => {
    setSelectedLanguage(e.target.value);
    // localStorage.setItem("lang", e.target.value)
  };

  const handleChangeLanguage = () => {
    i18n.changeLanguage(selectedLanguage);
    getData({
      url: "/change-lang",
      body: {
        language:selectedLanguage
      }
    })
    localStorage.setItem("lang", selectedLanguage)
  };

  return (
    <div className={className}>
      <div>
        {languageOptions.map((option) => (
          <label key={option.value} className={styles.radio_label}>
            <input
              type="radio"
              name="language"
              value={option.value}
              checked={selectedLanguage.includes(option.value)}
              onChange={handleChange}
            />
            <span
              className={`${styles["radio-custom"]} ${
                selectedLanguage.includes(option.value) ? styles.selected : ""
              }`}
            ></span>
            {option.label}
          </label>
        ))}{" "}
      </div>
      <div className={styles.button_languages}>
        <Button
          onClick={handleChangeLanguage}
          variant="outline"
          isActive={selectedLanguage !== i18n.language}
        >
          {t("Сохранить изменения")}
        </Button>
      </div>
    </div>
  );
};
