import React, { useEffect, useState } from "react";
import { ReactComponent as ArchiveIcon } from "../../assets/img/dropDownArchive.svg";
import { ReactComponent as DeleteIcon } from "../../assets/img/DropDownDelete.svg";
import { ReactComponent as InIcon } from "../../assets/img/DropDownIn.svg";
import { ReactComponent as OutIcon } from "../../assets/img/DropDownOut.svg";
import styles from "./SubMenuDashboard.module.css";
import { useTheme } from "../ui/ChangeTheme/context/ThemeContext";
import axiosInstance from "../../utils/axiosInstance";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useUserData } from "../../context/UserProvider";

const getAllProklads = async () => {
  try {
    const { data } = await axiosInstance({
      url: "/get-proklads",
      method: "post",
      data: { all: true },
    });
    return data.message;
  } catch (err) {
    console.log(err);
  }
};

export const SubMenuDashboard = ({
  data,
  onArchive,
  isArchived,
  onUnArchive,
  onRemove,
  isOpenReverse,
}) => {
  const { t } = useTranslation();
  const { userData } = useUserData();
  const { theme } = useTheme();
  const [inOptionsSelect, setInOptionsSelect] = useState([]);
  const [outOptionsSelect, setOutOptionsSelect] = useState([]);
  const [isOpenInSelect, setIsOpenInSelect] = useState(false);
  const [isOpenOutSelect, setIsOpenOutSelect] = useState(false);

  useEffect(() => {
    const fetchProklads = async () => {
      const { data } = await getAllProklads();
      const inItems = [];
      const outItems = [];

      data.forEach((el) => {
        if (el.type === "in") {
          inItems.push(el);
        } else {
          outItems.push(el);
        }
      });

      setInOptionsSelect(inItems);
      setOutOptionsSelect(outItems);
    };
    if (userData.role > 5) {
      fetchProklads();
    }
  }, [userData.role]);

  const changeProkladType = async (host, domain_name, typeToChange) => {
    try {
      const { data } = await axiosInstance({
        url: "/connect-proklad",
        method: "post",
        data: { host, domain_name, typeToChange },
      });
      toast.success(t("Успех!"));
      return data.message;
    } catch (err) {
      console.log(err);
      toast.error(t("Ошибка!"));
    }
  };

  const handleChangeTypeIn = (host) => {
    changeProkladType(host, data.domain_name, "in");
    setIsOpenInSelect(false);
  };

  const handleChangeTypeOut = (host) => {
    changeProkladType(host, data.domain_name, "out");
    setIsOpenOutSelect(false);
  };

  const handleRemoveProklad = (type) => {
    setIsOpenInSelect(false);
    setIsOpenOutSelect(false);
    changeProkladType(undefined, data.domain_name, type);
  };

  return (
    <ul data-theme={theme} className={styles.container}>
      <li
        onClick={!isArchived ? onArchive : onUnArchive}
        className={styles.item}
      >
        <ArchiveIcon style={{ minWidth: "15px" }} />{" "}
        <span>{!isArchived ? t("Архивировать") : t("Разархивировать")}</span>
      </li>

      <li onClick={onRemove} className={styles.item}>
        <DeleteIcon /> <span>{t("Удалить")}</span>
      </li>

      {userData.role > 5 && (
        <>
          <div className={styles.divider}></div>
          <li className={styles.item}>
            <div className={styles["wrapper-select"]}>
              <div
                onClick={() => {
                  setIsOpenInSelect((prev) => !prev);
                  setIsOpenOutSelect(false);
                }}
              >
                <InIcon /> <span>{t("Поменять IN")}</span>
              </div>
              {isOpenInSelect && (
                <ul
                  className={classNames(styles["select-box"], {
                    [styles.reverse]: isOpenReverse,
                  })}
                >
                  <li
                    onClick={() => handleRemoveProklad("in")}
                    className={styles["select-box-item"]}
                  >
                    {t("Удал. прокладку")}
                  </li>
                  {inOptionsSelect.map((el) => (
                    <li
                      key={el.id}
                      onClick={() => handleChangeTypeIn(el.host)}
                      className={styles["select-box-item"]}
                    >
                      {el.host}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </li>
          <li className={styles.item}>
            <div className={styles["wrapper-select"]}>
              <div
                onClick={() => {
                  setIsOpenOutSelect((prev) => !prev);
                  setIsOpenInSelect(false);
                }}
              >
                <OutIcon /> <span>{t("Поменять OUT")}</span>
              </div>
              {isOpenOutSelect && (
                <ul
                  className={classNames(styles["select-box"], {
                    [styles.reverse]: isOpenReverse,
                  })}
                >
                  <li
                    onClick={() => handleRemoveProklad("out")}
                    className={styles["select-box-item"]}
                  >
                    {t("Удал. прокладку")}
                  </li>
                  {outOptionsSelect.map((el) => (
                    <li
                      key={el.id}
                      onClick={() => handleChangeTypeOut(el.host)}
                      className={styles["select-box-item"]}
                    >
                      {el.host}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </li>
        </>
      )}
    </ul>
  );
};
