import { useEffect, useState } from "react";
import styles from "./ChangeTheme.module.css";
import { Button } from "../Button/Button";
import { useTheme } from "./context/ThemeContext";
import { useTranslation } from "react-i18next";
import { getData } from "../../../api";
import axiosInstance from "../../../utils/axiosInstance";

export const ChangeTheme = ({ className }) => {
  const { theme, setTheme } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);
  const { t } = useTranslation();
  useEffect(() => {
    setSelectedTheme(theme);
  }, [theme]);

  const languageOptions = [
    { label: t("Светлая"), value: "light" },
    { label: t("Тёмная"), value: "dark" },
  ];
  useEffect(() => {
    const fetchMe = async () => {
      const {data} = await axiosInstance({
        url: "/me",
        method: "post",
      });
      console.log(data)
      if(data){
        localStorage.setItem("theme", data.theme)
        setTheme(data.theme)
      }
    }

    fetchMe()
  }, [])
  
  const handleChange = (e) => {
    setSelectedTheme(e.target.value);
  };

  const save = () => {
    setTheme(selectedTheme);
    
    getData({
      url: "/change-theme",
      body: {
        theme:selectedTheme
      }
    })
  };

  return (
    <div className={className}>
      <h1 className={styles.changeTheme_text}>{t("Смена темы")}</h1>
      <div className={styles.changeTheme}>
        {languageOptions.map((option) => (
          <label key={option.value} className={styles.radio_label}>
            <input
              type="radio"
              name="theme"
              value={option.value}
              checked={selectedTheme === option.value}
              onChange={handleChange}
            />
            <span
              className={`${styles["radio-custom"]} ${
                selectedTheme === option.value ? styles.selected : ""
              }`}
            ></span>
            {option.label}
          </label>
        ))}
      </div>
      <div className={styles.button_theme}>
        <Button
          variant="outline"
          onClick={save}
          className={styles.button}
          isActive={selectedTheme !== theme}
        >
          {t("Сохранить изменения")}
        </Button>
      </div>
    </div>
  );
};
