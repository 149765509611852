import React, { useEffect, useRef, useState } from "react";
import styles from "./AddDomain.module.css";
import { Button } from "../Button/Button";
import { useTranslation } from "react-i18next";
import { ReactComponent as UrlIcon } from "../../assets/img/urlIcon.svg";
import { ReactComponent as PlusIcon } from "../../assets/img/plus.svg";
import { v1 } from "uuid";
import axiosInstance from "../../utils/axiosInstance";
import { useUserData } from "../../context/UserProvider";
import SelectOne from "../SelectOne/SelectOne";
import { useTheme } from "../ui/ChangeTheme/context/ThemeContext";
import { toast } from "react-toastify";

export const AddDomain = ({ fetchData }) => {
  const { t } = useTranslation();
  const [addDomainForms, setAddDomainForms] = useState({
    [v1()]: {
      domain: "",
      domain_username: "",
      dev: "",
      inData: "",
      outData: "",
    },
  });
  const [isReset, setIsReset] = useState(false);

  const { theme } = useTheme();

  const addForm = () => {
    setAddDomainForms((prev) => ({
      ...prev,
      [v1()]: {
        domain: "",
        username: "",
        dev: "",
        inData: "",
        outData: "",
      },
    }));
  };

  const handleSubmitData = async () => {
    try {
      const promises = Object.keys(addDomainForms).map((item) => {
        const formData = addDomainForms[item];
        if (!formData.domain || !formData.username) {
          throw new Error("Пожалуйста, заполните все обязательные поля.");
        }

        return axiosInstance.post("/add-accepted-domain", {
          domain: formData.domain,
          domain_username: formData.username,
          count: 0,
          dev: formData.dev || undefined,
          inData: formData.inData,
          outData: formData.outData,
        });
      });

      const response = await Promise.all(promises);
      response.forEach((el) => {
        toast.success(el?.data?.message?.domain_status);
      });

      fetchData(0);
      setIsReset((prev) => !prev);

      setAddDomainForms({
        [v1()]: {
          domain: "",
          username: "",
          dev: "",
          inData: "",
          outData: "",
        },
      });
    } catch (error) {
      console.error("Произошла ошибка:", error.message || error);
      toast.error(t("Ошибка!"));
    }
  };

  const handleCancle = () => {
    setAddDomainForms({
      [v1()]: {
        domain: "",
        username: "",
        dev: "",
        inData: "",
        outData: "",
      },
    });
    setIsReset((prev) => !prev);
  };

  return (
    <>
      <div data-theme={theme} className={styles.container}>
        <div className={styles["wrapper-block"]}>
          {Object.keys(addDomainForms).map((el, i) => {
            return (
              <FormDomain
                key={`${i}-${isReset ? true : false}`}
                id={el}
                setAddDomainForms={setAddDomainForms}
              />
            );
          })}
          <button onClick={addForm} className={styles["add-button"]}>
            <PlusIcon />
          </button>
        </div>
        <div className={styles["container-buttons"]}>
          <Button onClick={handleCancle}>{t("Отменить")}</Button>
          <Button onClick={handleSubmitData}>{t("Сохранить")}</Button>
        </div>
      </div>
    </>
  );
};

const FormDomain = ({ id, setAddDomainForms }) => {
  const { t } = useTranslation();
  const { userData } = useUserData();
  const { theme } = useTheme();
  const [inputDomain, setInputDomain] = useState("");
  const [optionUserName, setOptionsUserName] = useState("");
  const [optionDev, setOptionDev] = useState("");
  const [optionIn, setOptionIn] = useState("");
  const [optionOut, setOptionOut] = useState("");
  const [selectUserName, setSelectUserName] = useState([]);
  const [selectDevName, setSelectDevName] = useState([]);
  const [selectIn, setSelectIn] = useState([]);
  const [selectOut, setSelectOut] = useState([]);

  const handleChange = (value, setState) => {
    setState(value);
  };

  useEffect(() => {
    setAddDomainForms((prev) => {
      return {
        ...prev,
        [id]: {
          domain: inputDomain,
          username: optionUserName,
          dev: optionDev,
          inData: optionIn,
          outData: optionOut,
        },
      };
    });
  }, [inputDomain, optionUserName, optionDev, optionIn, optionOut]); 

  const getSelectsOptions = async () => {
    const { data: users } = await axiosInstance({
      url: "/users",
      method: "post",
      data: {
        queryData: 1,
        count: 99999999,
      },
    });
    const usersMap = users.data.map((el) => ({
      value: el.username,
      label: el.username,
    }));

    setSelectUserName(usersMap);

    if (userData?.role > 5) {
      const { data: devs } = await axiosInstance({
        url: "/get-devs",
        method: "post",
        data: {},
      });
      const devsMap = devs.message.map((el) => ({
        value: el.username,
        label: el.username,
      }));
      setSelectDevName(devsMap);
    }
    const { data: proklads } = await axiosInstance({
      url: "/get-proklads",
      method: "post",
      data: { all: true },
    });

    const Allproklads = proklads.message.data;
    let prokladsIn = [];
    let prokladsOut = [];

    for (let i = 0; i < Allproklads.length; i++) {
      if (Allproklads[i].type === "in") {
        prokladsIn.push({
          label: Allproklads[i].host,
          value: Allproklads[i].host,
        });
      } else {
        prokladsOut.push({
          label: Allproklads[i].host,
          value: Allproklads[i].host,
        });
      }
    }

    setSelectIn(prokladsIn);
    setSelectOut(prokladsOut);
  };

  useEffect(() => {
    getSelectsOptions();
  }, []);

  return (
    <div data-theme={theme} className={styles.block}>
      <div className={styles["container-input"]}>
        <input
          value={inputDomain}
          onChange={(e) => setInputDomain(e.target.value)}
          placeholder="URL"
          type="text"
          className={styles["input-domain"]}
        />
        <UrlIcon className={styles.urlIcon} />
      </div>
      <SelectOne
        className={styles.select}
        subName={t("Выбор юзеров")}
        options={selectUserName}
        onChange={(value) => handleChange(value, setOptionsUserName)}
      />
      {userData?.role > 5 && (
        <SelectOne
          className={styles.select}
          subName={t("Выбор дева")}
          options={selectDevName}
          onChange={(value) => handleChange(value, setOptionDev)}
        />
      )}
      <SelectOne
        selectAll
        className={styles.select}
        subName={t("Выбор IN")}
        options={selectIn}
        onChange={(value) => handleChange(value, setOptionIn)}
      />
      <SelectOne
        selectAll
        className={styles.select}
        subName={t("Выбор OUT")}
        options={selectOut}
        onChange={(value) => handleChange(value, setOptionOut)}
      />
    </div>
  );
};
