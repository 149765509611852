import React, {useEffect, useState} from "react";
import styles from "./Dashboard.module.css";
import {Bar, BarChart, LabelList, ResponsiveContainer, XAxis} from "recharts";
import {ChartChanger} from "../../../../components/ChartChanger/ChartCahnger";
import {useTheme} from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import moment from "moment";
import "moment/locale/ru";
import {useTranslation} from "react-i18next";
import {Skeleton} from "../../../../components/Skeleton/Skeleton";
import {getData} from "../../../../api";


export const DashboardChart = () => {
    const {theme} = useTheme();
    const {t, i18n} = useTranslation();
    const [chartData, setChartData] = useState([]);

    const dataForChartChanger = [
        {name: t("День"), value: "day"},
        {name: t("Месяц"), value: "month"},
        {name: t("Год"), value: "year"},
        {name: t("Все время"), value: "all_time"},
    ];

    const [selectedChart, setSelectedChart] = useState(
        dataForChartChanger[dataForChartChanger.length - 1].value
    );

    useEffect(() => {
        const getChartData = async () => {
            try {
                // Replace with your API call logic
                const result = await getData({
                    url: "/filter-full-graphs",
                    body: {date: selectedChart},
                });
                console.log(result)
                const formattedData = Object.keys(result)
                    .filter((key) => key !== "total")
                    .map((date) => {
                        const originalDate = moment(date, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH"], true);
                        if (!originalDate.isValid()) return null;
                        return {
                            value: result[date],
                            time:
                                selectedChart === "day"
                                    ? originalDate.locale(i18n.language).format("D MMM HH:mm")
                                    : originalDate.locale(i18n.language).format("D MMM"),
                            originalDate: originalDate.toDate(),
                        };
                    })
                    .filter((item) => item !== null)
                    .sort((a, b) => a.originalDate - b.originalDate);

                setChartData(formattedData);
            } catch (error) {
                console.error("Error fetching chart data", error);
            }
        };

        getChartData();
    }, [selectedChart, i18n.language]);
    const minBarWidth = 322;
    const containerWidth = 1340;
    const calculatedWidth = Math.max(chartData.length * minBarWidth, containerWidth);

    return (
        <div data-theme={theme} className={styles.container}>
            <div className={styles.header}>
                <h2>{t("График")}</h2>
                <ChartChanger
                    items={dataForChartChanger}
                    selectedValue={selectedChart}
                    setSelectedValue={setSelectedChart}
                />
            </div>
            <div
                className={styles.chartWrapper}
                style={{
                    overflowX: chartData.length * minBarWidth > 300 ? "auto" : "hidden",
                    whiteSpace: "nowrap",
                }}
            >
                {chartData.length > 0 ? (
                    <div
                        style={{
                            width: containerWidth,
                            height: 322,
                            marginTop: "46px",
                            display: "inline-block",
                        }}
                    >
                        <BarChart
                            width={containerWidth}
                            height={322}
                            data={chartData}
                            barCategoryGap={10}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 52,
                            }}
                        >
                            <XAxis
                                interval={0}
                                axisLine={false}
                                dataKey="time"
                                tickLine={false}
                                tick={<CustomizedTick/>}
                            />
                            <Bar
                                dataKey="value"
                                fill="rgb(207, 188, 255)"
                                stroke="black"
                                minPointSize={32}
                                maxBarSize={chartData.length < 20 ? "auto" : 32}
                                strokeWidth={1}
                                radius={4}
                            >
                                <LabelList
                                    className={styles["chart-label"]}
                                    dataKey="value"
                                    position="top"
                                />
                            </Bar>
                        </BarChart>
                    </div>
                ) : (
                    <Skeleton style={{marginTop: "46px"}} width="100%" height={270}/>
                )}
            </div>
        </div>
    );
};

const CustomizedTick = ({x, y, payload}) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={30} fill="#666" textAnchor="middle">
                {payload.value.split(" ").map((line, index) => (
                    <tspan
                        className={styles["tick-span"]}
                        fontSize={16}
                        key={index}
                        x={0}
                        dy={index === 0 ? 20 : 24}
                    >
                        {line}
                    </tspan>
                ))}
            </text>
        </g>
    );
};