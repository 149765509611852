import React, { useEffect, useState } from "react";
import styles from "./ChartUsers.module.css";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

import { useTranslation } from "react-i18next";

import { ChartChanger } from "../../../../components/ChartChanger/ChartCahnger";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import { Skeleton } from "../../../../components/Skeleton/Skeleton";
import { getData } from "../../api";
import axiosInstance from "../../../../utils/axiosInstance";
import { avatars } from "../../../../utils/Avatars/avatars";

export const ChartUsers = ({ dataForChartChanger }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  const [chartDataUsers, setChartDataUsers] = useState();
  const [selectedChartUsers, setSelectedChartUsers] = useState(
    dataForChartChanger[dataForChartChanger.length - 1].value
  );

  const chartHeight = chartDataUsers?.length * 40 || 0;

  useEffect(() => {
    console.log("Fetching data with parameters:", {
      date: selectedChartUsers,
    });

    const getChartCountryData = async () => {
      const data = await getData({
        url: "/filter-dev-statistics",
        body: {
          date: selectedChartUsers,
        },
      });

      // Проверяем данные из запроса
      console.log("Data from server:", data);

      const { data: users } = await axiosInstance({
        url: "/users",
        method: "post",
        data: {
          queryData: 1,
          count: 99999999,
        },
      });

      console.log("Users data:", users);

      const usersObj = {};
      users.data.forEach((el) => {
        usersObj[el.username] = el.avatar;
      });

      if (data.error) {
        console.log("Error in data:", data.error);
        return;
      }

      let res = Object.keys(data).map((el) => ({
        name: el,
        value: data[el],
        avatar: usersObj[el] % 10,
      }));

      setChartDataUsers(res);
    };
    getChartCountryData();
  }, [selectedChartUsers]);

  return (
    <div data-theme={theme} className={styles.container}>
      <div className={styles.header}>
        <h2>{t("График по количеству добавленных доменов")}</h2>
        <ChartChanger
          items={dataForChartChanger}
          selectedValue={selectedChartUsers}
          setSelectedValue={setSelectedChartUsers}
        />
      </div>
      <div
        style={{ overflowY: chartDataUsers?.length >= 7 ? "auto" : "hidden" }}
        className={styles["chart-wrapper"]}
      >
        {chartDataUsers ? (
          <ResponsiveContainer width="100%" height={chartHeight}>
            <BarChart
              layout="vertical"
              data={chartDataUsers}
              barSize={24}
              barGap={16}
              margin={{
                top: 0,
                right: 0,
                left: 60,
                bottom: 5,
              }}
            >
              <XAxis type="number" domain={[0, "dataMax"]} hide />
              <YAxis
                interval={0}
                axisLine={false}
                tickLine={false}
                type="category"
                dataKey="name"
                tick={<CustomYAxisTick options={chartDataUsers} />}
                width={50}
              />

              <Bar
                dataKey="value"
                fill="rgb(207, 188, 255)"
                stroke="black"
                strokeWidth={1}
                barSize={24}
                radius={4}
                label={{ position: "insideLeft", fill: "black" }}
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Skeleton width="100%" height="100%" />
        )}
      </div>
    </div>
  );
};

function randomInteger(min, max) {
  let rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
}

const CustomYAxisTick = ({ x, y, payload, options }) => {
  const { theme } = useTheme();
  const { value } = payload;
  const item = options.find((d) => d.name === value);
  return (
    <g textAnchor="start" transform={`translate(${x},${y})`}>
      <foreignObject x={-100} y={-10} width="100%" height={24}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <div style={{ width: "24px" }}>
            {item.avatar >= 0
              ? avatars[item.avatar]
              : avatars[randomInteger(0, 9)]}
          </div>
          <div className={styles.text} title={item.name}>
            {item.name}
          </div>
        </div>
      </foreignObject>
    </g>
  );
};

