import React from "react";

import { Button } from "../../../../components/Button/Button";
import { ReactComponent as ButtonIcon } from "../../../../assets/img/dashboardDwnld.svg";
import styles from "./MapsHeader.module.css";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { getData } from "../../../../modules/DashboardPage/api";
import axiosInstance from "../../../../utils/axiosInstance";

const MapsHeader = ({
  setIsModalOpen,
  setSelectedValues,
  isActiveDoubles,
  setIsActiveDoubles,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const navData = [t("Уникальные"), t("Дубли")];

  const handleClickDubles = (index) => {
    setIsActiveDoubles(index);
    setSelectedValues((prev) => ({
      ...prev,
      double: [index],
    }));
  };

  const handleDownloadBins = async () => {
    try {
      const response = await axiosInstance({
        url: "/get-bins",
        method: "post",
        data: {},
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;

      link.download = "bins.txt";
      link.click();
    } catch (error) {
      console.error("Error downloading bins:", error);
      return { error: "Failed to download bins." };
    }
  };

  const handleGetNotOpenedBins = async () => {
    try {
      const response = await axiosInstance({
        url: "/get-bins",
        method: "post",
        data: {
          open: "1"
        },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;

      link.download = "not_opened_bins.txt";
      link.click();
    } catch (error) {
      console.error("Error getting not opened bins:", error);
      return { error: "Failed to get not opened bins." };
    }
  };

  return (
    <>
      <div data-theme={theme} className={styles.header}>
        <h2>{t("Список и фильтрация карт")}</h2>
        <div style={{ display: "flex", gap: "1cm" }}>
          <Button
            className={styles["btn-dwnld"]}
            style={{
              backgroundColor: theme === "dark" ? "#CFBCFF" : "transparent",
              color: theme === "dark" ? "#1e1e1e" : "#6A696B",
              border: "1px solid",
              borderColor: theme === "dark" ? "#191a23" : "#6A696B",
            }}
            onClick={handleGetNotOpenedBins}
          >
            {t("Скачать бины не открытых")} <ButtonIcon className={styles.icon} />
          </Button>
          <Button
            className={styles["btn-dwnld"]}
            style={{
              backgroundColor: theme === "dark" ? "#CFBCFF" : "transparent",
              color: theme === "dark" ? "#1e1e1e" : "#6A696B",
              border: "1px solid",
              borderColor: theme === "dark" ? "#191a23" : "#6A696B",
            }}
            onClick={handleDownloadBins}
          >
            {t("Скачать бины")} <ButtonIcon className={styles.icon} />
          </Button>
          <Button
            className={styles["btn-dwnld"]}
            style={{
              backgroundColor: theme === "dark" ? "#CFBCFF" : "transparent",
              color: theme === "dark" ? "#1e1e1e" : "#6A696B",
              border: "1px solid",
              borderColor: theme === "dark" ? "#191a23" : "#6A696B",
            }}
            onClick={() => setIsModalOpen(true)}
          >
            {t("Скачать все карты")} <ButtonIcon className={styles.icon} />
          </Button>
        </div>
      </div>
      <div className={styles.nav}>
        {navData.map((el, index) => (
          <button
            onClick={() => {
              handleClickDubles(index);
              setIsActiveDoubles(index);
            }}
            key={index}
            className={classNames(isActiveDoubles === index && styles.active)}
          >
            {el}
          </button>
        ))}
      </div>
    </>
  );
};

export default MapsHeader;