import React, { useState, useEffect } from "react";
import styles from "./DashBoardChartCountry.module.css";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { ChartChanger } from "../ChartChanger/ChartCahnger";
import { useTheme } from "../ui/ChangeTheme/context/ThemeContext";
import { useTranslation } from "react-i18next";
import { Skeleton } from "../Skeleton/Skeleton";
import NoCountry from "../NoCountry/NoCountry";
import { getData } from "../../api";

export const DashBoardChartCountry = ({
  dataForChartChanger,
  chartData,
  selectedChart,
  setSelectedChart,
  setChartDataCountry
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  const chartHeight = chartData?.length * 40 || 0;

  // Объединённое состояние для чекбокса и выбранного фильтра
  const [filterOptions, setFilterOptions] = useState({
    isCheckboxChecked: false,
    selectedChart: selectedChart || "all_time", // Изначальное значение selectedChart
  });

  // Формирование тела запроса
  const getRequestBody = () => {
    const requestBody = {
      date: filterOptions.selectedChart,
    };

    // Если чекбокс выключен, добавляем open: "1"
    if (filterOptions.isCheckboxChecked) {
      requestBody.open = "1";
    }

    return requestBody;
  };

  // Получение данных
  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const requestBody = getRequestBody();

        const result = await getData({
          url: "/filter-by-countries",
          body: requestBody,
        });

        console.log("Raw response from server:", result);

        if (!result || typeof result !== "object" || Array.isArray(result)) {
          console.error(
            "Некорректный ответ от API. Ожидался объект, получено:",
            result
          );
          return;
        }

        if (result.error) {
          console.error("Ошибка API:", result.error);
          return;
        }

        let resp = Object.keys(result).map((el) => ({
          name: el,
          value: result[el],
          flag: `https://flagsapi.com/${el}/flat/64.png`,
        }));

        setChartDataCountry(resp);
      } catch (error) {
        console.error("Ошибка при запросе данных:", error);
      }
    };

    fetchChartData();
  }, [filterOptions, setChartDataCountry]);


  const handleCheckboxChange = () => {
    setFilterOptions((prev) => ({
      ...prev,
      isCheckboxChecked: !prev.isCheckboxChecked,
    }));
  };


  const handleChartChange = (newChart) => {
    setFilterOptions((prev) => ({
      ...prev,
      selectedChart: newChart,
    }));
    setSelectedChart(newChart);
  };

  return (
    <div data-theme={theme} className={styles.container}>
      <div className={styles.header}>
        <h2>{t("График по странам")}</h2>
        <div className={styles.switchContainer}>
          <span className={styles.labelText}>{t("Показать в наличии")}</span>
          <label className={styles.switch}>
            <input
              type="checkbox"
              checked={filterOptions.isCheckboxChecked}
              onChange={handleCheckboxChange}
            />
            <span className={styles.slider}></span>
          </label>
        </div>
        <ChartChanger
          items={dataForChartChanger}
          selectedValue={selectedChart}
          setSelectedValue={handleChartChange}
        />
      </div>

      <div
        style={{ overflowY: chartData?.length >= 7 ? "auto" : "hidden" }}
        className={styles["chart-wrapper"]}
      >
        {chartData ? (
          <ResponsiveContainer width="100%" height={chartHeight}>
            <BarChart
              layout="vertical"
              data={chartData}
              barSize={24}
              barGap={16}
              margin={{
                top: 0,
                right: 0,
                left: 50,
                bottom: 5,
              }}
            >
              <XAxis type="number" domain={[0, "dataMax"]} hide />
              <YAxis
                interval={0}
                axisLine={false}
                tickLine={false}
                type="category"
                dataKey="name"
                tick={<CustomYAxisTick options={chartData} />}
                width={20}
              />
              <Bar
                dataKey="value"
                fill="rgb(207, 188, 255)"
                stroke="black"
                strokeWidth={1}
                barSize={24}
                radius={4}
                label={{ position: "insideLeft", fill: "black" }}
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Skeleton width="100%" height="100%" />
        )}
      </div>
    </div>
  );
};

const CustomYAxisTick = ({ x, y, payload, options }) => {
  const { theme } = useTheme();
  const { value } = payload;
  const item = options.find((d) => d.name === value);

  return (
    <g transform={`translate(${x},${y})`}>
      {item.name === "not_finded" ? (
        <>
          <NoCountry
            x={-60}
            y={-13}
            width={32}
            height={32}
            style={{
              fill: theme === "dark" ? "#fff" : "#000",
              stroke: theme === "dark" ? "#fff" : "#000",
            }}
          />
          <text
            className={styles["tick-span"]}
            x={-1}
            y={0}
            dy={4}
            textAnchor="end"
          >
            NA
          </text>
        </>
      ) : (
        <>
          <image
            clipPath="inset(0% round 14px)"
            href={item.flag}
            x={-60}
            y={-17}
            height={32}
            width={32}
            alt={item.name}
          />
          <text
            className={styles["tick-span"]}
            x={-1}
            y={0}
            dy={4}
            textAnchor="end"
          >
            {item.name}
          </text>
        </>
      )}
    </g>
  );
};
