import styles from "./Header.module.css";
import { useUserData } from "../../context/UserProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/img/arrowBack.svg";
import { useTheme } from "../../components/ui/ChangeTheme/context/ThemeContext";
import { useTranslation } from "react-i18next";
import { ReactComponent as LogoutIcon } from "../../assets/img/logout.svg";
import Cookies from "js-cookie";
import { avatars } from "../../utils/Avatars/avatars";

const Header = () => {
  const { userData } = useUserData();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { t } = useTranslation();

  const handleLogout = () => {
    Cookies.remove("accessToken");
    navigate("/login");
  };

  const handleBackClick = () => {
    if (window.history.length > 1) {
      navigate(-1);
      setTimeout(() => {

        if (pathname === window.location.pathname) {
          navigate("/");
        }
      }, 100);
    } else {
      navigate("/");
    }
  };

  const getPageName = (path) => {
    switch (true) {
      case path === "/":
        return t("Дашборд");
      case path === "/admin":
        return t("Админ");
      case path === "/profile":
        return t("Профиль");
      case path === "/maps":
        return t("Карты");
      case path === "/proxies":
        return t("Прокладки");
      case path.startsWith("/dashboard/"):
        return (
          <div>
            <BackIcon
              fill={theme === "dark" ? "white" : null}
              onClick={handleBackClick}
              style={{ marginRight: "20px", cursor: "pointer" }}
            />
            {`${t("Домен")} ${path.split("/").at(-1)}`}
          </div>
        );
      default:
        return t("Неизвестная страница");
    }
  };

  return (
    <div data-theme={theme} className={styles.container}>
      <h1 className={styles.page_name}>{getPageName(pathname)}</h1>
      <div className={styles.profile}>
        <h2 className={styles.user_name}>{userData?.username}</h2>
        <div className={styles.avatar}>
          {userData?.avatar ? avatars[userData.avatar % 10] : avatars[0]}
        </div>

        <div onClick={handleLogout} className={styles.logout}>
          <LogoutIcon width={20} height={20} />
        </div>
      </div>
    </div>
  );
};

export default Header;
