import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import styles from "./Modal.module.css";
import axiosInstance from "../../../../utils/axiosInstance";
import classNames from "classnames";
import {ReactComponent as CloseIcon} from "../../../../assets/img/close.svg";

const Modal = ({setIsModalOpen}) => {
    const {t} = useTranslation();
    const {theme} = useTheme();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleOverlayClick = (e) => {
        const targetClass = e.target.className || "";
        if (
            typeof targetClass === "string" &&
            (targetClass.includes(styles.overlay) ||
                targetClass.includes(styles.close))
        ) {
            setIsModalOpen(false);
        }
    };

    useEffect(() => {
        async function checkProxy() {
            setIsLoading(true);
            setError(null);
            try {
                const {data} = await axiosInstance.post("/check-proklads", {});
                let proxy = [];
                Object.keys(data.message).forEach((el) => {
                    if (el !== "total_invalid" && el !== "total_valid") {
                        proxy.push({[el]: data.message[el]});
                    }
                });
                setData(proxy);
            } catch (err) {
                console.log(err);
                setError(t("Ошибка при загрузке данных"));
            } finally {
                setIsLoading(false);
            }
        }

        checkProxy();
    }, [t]);

    return (
        <div className={styles.overlay} onClick={handleOverlayClick}>
            <div className={styles.modal} data-theme={theme}>
                <div className={styles["title-container"]}>
                    <h2 className={styles.title}>{t("Список прокладок")}</h2>
                    <button
                        className={styles.close}
                        onClick={() => setIsModalOpen(false)}
                    >
                        <CloseIcon stroke={theme === "dark" ? "white" : "black"}/>
                    </button>
                </div>
                <div className={styles["data-container"]}>
                    {isLoading && <p>{t("Загрузка...")}</p>}
                    {error && <p className={styles.error}>{error}</p>}
                    {!isLoading && !error && data?.map((el, index) => (
                        <div key={index} className={styles["info-container"]}>
                            <h3>{Object.keys(el)[0]}</h3>
                            <div
                                className={classNames(styles["host-circle"], {
                                    [styles.isActive]: Object.values(el)[0],
                                })}
                            ></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Modal;