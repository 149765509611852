import React, { useEffect, useState } from "react";
import styles from "./Table.module.css";
import { CustomCheckbox } from "../../../../components/Checkbox/Checkbox";
import { ReactComponent as EditIcon } from "../../../../assets/img/editIcon.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/img/trashMaps.svg";
import { ReactComponent as CalendarIcon } from "../../../../assets/img/calendarGreen.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/img/successIcon.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/img/xIcon.svg";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";
import { Input } from "./Input";
import { TableSkeleton } from "./TableSkeleton";
import axiosInstance from "../../../../utils/axiosInstance";
import { toast } from "react-toastify";

const editProklad = async ({ endDate, comment, host }) => {
  try {
    const { data } = await axiosInstance({
      url: "/edit-proklad",
      method: "post",
      data: { endDate, comment, host },
    });
    return data.message;
  } catch (err) {
    console.log(err);
  }
};

const Table = ({
  isLoading,
  data,
  onChange,
  selectedRow,
  rowsPerPage,
  handleDeleteProklads,
  getAllProklads,
  handleHeaderCheckboxChange,
  allProkladsLength,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme;

  const headerData = [
    {
      name: (
        <CustomCheckbox
          onChange={(value, isChecked) => handleHeaderCheckboxChange(isChecked)}
          isChecked={selectedRow.length === allProkladsLength}
        />
      ),
      width: 50,
    },
    {
      name: t("URL"),
      width: 199,
    },
    {
      name: t("Гео"),
      width: 64,
    },
    {
      name: t("Дата создания"),
      width: 164,
    },
    {
      name: t("Тип"),
      width: 55,
    },
    {
      name: t("Кол-во доменов"),
      width: 86,
    },
    {
      name: t("Истекает"),
      width: 130,
    },
    {
      name: t("Комментарий"),
      width: 214,
    },
  ];

  return (
    <div data-theme={theme} className={styles["table-container"]}>
      <table className={styles.table}>
        <thead>
          <tr>
            {headerData.map((el) => {
              return (
                <th
                  key={el.name}
                  onClick={el.onClick}
                  style={{ width: el.width }}
                >
                  <div> {el.name}</div>
                </th>
              );
            })}
            <th style={{ width: "90px" }}></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            Array.from({ length: rowsPerPage }).map((_, i) => (
              <TableSkeleton key={i} />
            ))
          ) : !data || !data.length ? (
            <tr>
              <td
                style={{ textAlign: "center" }}
                className={styles["not-found-td"]}
                colSpan={9}
              >
                {t("Нет данных")}
              </td>
            </tr>
          ) : (
            data.map((row, i) => {
              return (
                <TableRow
                  key={row.id}
                  getAllProklads={getAllProklads}
                  handleDeleteProklads={handleDeleteProklads}
                  row={row}
                  selectedRow={selectedRow}
                  onChange={onChange}
                />
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

const TableRow = ({
  row,
  onChange,
  selectedRow,
  handleDeleteProklads,
  getAllProklads,
}) => {
  const { t } = useTranslation();
  const [isEdited, setIsEdited] = useState(false);
  const [inputDate, setInputDate] = useState(row.endAt);
  const [inputComment, setInputComment] = useState(row.comment);
  const createdAt = row.createdAt.split(" ");

  const handleEdit = async () => {
    const result = await editProklad({
      endDate: moment(inputDate).format("DD.MM.YYYY"),
      comment: inputComment,
      host: row.host,
    });

    if (result === "edited") {
      toast.success(t("Успех!"));
      getAllProklads();
      setIsEdited(false);
      return;
    }

    toast.error(t("Ошибка!"));
  };

  const handleCancel = (date, comment) => {
    setInputDate(date);
    setInputComment(comment);
    setIsEdited(false);
  };

  return (
    <tr key={row.id}>
      <td>
        <CustomCheckbox
          value={row.host}
          onChange={(value, isChecked) => onChange(value, isChecked)}
          isChecked={selectedRow.includes(row.host)}
          name=""
        />
      </td>
      <td>
        <div
          title={row.host}
          style={{ maxWidth: "173px" }}
          className={styles["host-container"]}
        >
          {row.host}
          <div
            className={classNames(styles["host-circle"], {
              [styles.isActive]: row.is_active ? 1 : 0,
            })}
          ></div>
        </div>
      </td>
      <td>
        <div>
          <img
            style={{
              width: 32,
              height: 32,
              marginLeft: 0,
              borderRadius: "12px",
            }}
            src={`https://flagsapi.com/${row.geo}/flat/32.png`}
            alt={row.geo}
          />
        </div>
      </td>
      <td>
        <span>{createdAt[0]}</span>{" "}
        <span style={{ color: "#aeaeb2" }}>{createdAt[1]}</span>
      </td>
      <td>{row.type.toUpperCase()}</td>
      <td style={{ textAlign: "right", paddingRight: "20px" }}>{row.total}</td>
      <td>
        {isEdited ? (
          <Input
            onChange={(e) => setInputDate(e)}
            text={inputDate}
            type="date"
          />
        ) : (
          <div className={styles["wrapper-endAt"]}>
            {moment(inputDate, "DD.MM.YYYY").format("DD.MM.YYYY")}
            {DeadlineIcon(inputDate)}
          </div>
        )}
      </td>
      <td>
        <div className={styles.comment}>
          {" "}
          {isEdited ? (
            <Input
              onChange={(e) => setInputComment(e)}
              text={inputComment}
              type="text"
            />
          ) : (
            inputComment
          )}
        </div>
      </td>
      <td>
        <div style={{ position: "relative", display: "flex", gap: "20px" }}>
          {isEdited ? (
            <>
              <button onClick={handleEdit}>
                <CheckIcon height={18} />
              </button>
              <button onClick={() => handleCancel(row.endAt, row.comment)}>
                <CloseIcon stroke="#7C7C7C" />
              </button>
            </>
          ) : (
            <>
              <button onClick={() => setIsEdited(true)}>
                <EditIcon className={styles["edit-icon"]} />
              </button>
              <button onClick={() => handleDeleteProklads([row.host])}>
                <TrashIcon className={styles["trash-icon"]} />
              </button>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

const DeadlineIcon = (deadline) => {
  const today = moment();
  const dueDate = moment(deadline, "DD.MM.YYYY");

  const daysLeft = dueDate.diff(today, "days");

  let color;
  if (daysLeft < 0) {
    color = "#f13636"; // Просрочено
  } else if (daysLeft < 7) {
    color = "#f13636"; // Меньше недели
  } else if (daysLeft < 30) {
    color = "#f3bb1a"; // Меньше 30 дней
  } else {
    color = "#3cc13c"; // Больше 30 дней
  }

  return <CalendarIcon stroke={color} />;
};

export default Table;
