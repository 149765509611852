import React, { useEffect } from "react";
import styles from "./Pagination.module.css";
import classNames from "classnames";
import { ReactComponent as ArrowIcon } from "../../assets/img/paginationArrow.svg";
import { useTranslation } from "react-i18next";
import { useTheme } from "../ui/ChangeTheme/context/ThemeContext";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const Pagination = ({ totalPages, currentPage, setCurrentPage }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  // Извлекаем query-параметр из URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");
    if (page && !isNaN(page) && page <= totalPages) {
      setCurrentPage(parseInt(page)); // Устанавливаем текущую страницу, если параметр присутствует
    }
  }, [location.search, setCurrentPage, totalPages]);

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        pageNumbers.push(
          <NavLink
            key={i}
            to={`?page=${i}`} // Используем query-параметр для каждой страницы
            className={classNames(styles["page-number"], {
              [styles.active]: currentPage === i,
            })}
            onClick={(e) => {
              e.preventDefault(); // Предотвращаем дефолтное поведение
              setCurrentPage(i); // Обновляем текущую страницу
            }}
          >
            {i}
          </NavLink>
        );
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        pageNumbers.push(
          <span key={i} className={styles.dots}>
            ...
          </span>
        );
      }
    }
    return pageNumbers;
  };

  const handlePreviousPage = (e) => {
    e.preventDefault();
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      navigate(`?page=${currentPage - 1}`);
    }
  };

  const handleNextPage = (e) => {
    e.preventDefault();
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      navigate(`?page=${currentPage + 1}`);
    }
  };

  return (
    <div
      style={{ visibility: totalPages > 1 ? "visible" : "hidden" }}
      className={styles.pagination}
      data-theme={theme}
    >
      <NavLink
        to={`?page=${currentPage - 1}`}
        className={styles["page-button"]}
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
      >
        <ArrowIcon
          stroke={
            currentPage === 1
              ? "#757575"
              : theme === "dark"
              ? "#909090"
              : "#1e1e1e"
          }
          style={{ transform: "rotate(180deg)", marginRight: "10px" }}
        />{" "}
        <span>{t("Назад")}</span>
      </NavLink>

      {renderPageNumbers()}

      <NavLink
        to={`?page=${currentPage + 1}`}
        className={styles["page-button"]}
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
      >
        <span>{t("Дальше")}</span>{" "}
        <ArrowIcon
          stroke={
            currentPage === totalPages
              ? "#757575"
              : theme === "dark"
              ? "#909090"
              : "#1e1e1e"
          }
          style={{ marginLeft: "10px" }}
        />
      </NavLink>
    </div>
  );
};

export default Pagination;
