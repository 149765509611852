import React, { useState, useRef, Children, useEffect } from "react";
import ReactDOM from "react-dom";

import styles from "./submenuDots.module.css";
import { useTheme } from "../ui/ChangeTheme/context/ThemeContext";
import classNames from "classnames";
import { ReactComponent as DotsIcon } from "../../assets/img/dotsDashboardIcon.svg";

const SubmenuDots = ({ children, isOpenReverse }) => {
  const { theme } = useTheme();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = useRef(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      btnRef.current &&
      !btnRef.current.contains(event.target)
    ) {
      toggleDropdown();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);

      const rect = btnRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX - 140,
      });
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const dropdownContent = (
    <div
      ref={dropdownRef}
      style={{
        position: "absolute",
        top: menuPosition.top,
        left: menuPosition.left,
        zIndex: 9999,
      }}
      data-theme={theme}
      className={classNames(styles.dropdownMenu, {
        [styles.reverse]: isOpenReverse,
      })}
    >
      {children}
    </div>
  );

  return (
    <>
      <button ref={btnRef} onClick={toggleDropdown}>
        <DotsIcon />
      </button>
      {isOpen && ReactDOM.createPortal(dropdownContent, document.body)}
    </>
  );
};

export default SubmenuDots;
