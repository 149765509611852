import React, { useState, useEffect } from "react";
import { ReactComponent as EditIcon } from "../../../../assets/img/editDashbordTableIcon.svg";
import { ReactComponent as DwnldIcon } from "../../../../assets/img/download.svg";
import styles from "./DashboardDomainTable.module.css";
import { CustomCheckbox } from "../../../../components/Checkbox/Checkbox";
import { ReactComponent as TrashIcon } from "../../../../assets/img/trashMaps.svg";
import classNames from "classnames";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { TableSkeleton } from "./TableSkeleton";

const StatusButton = ({ string, status }) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(styles["status-btn"], {
        [styles.open]: status === "orange",
        [styles.close]: status === "green",
      })}
    >
      {t(string)}
    </div>
  );
};

export const DashboardDomainTable = ({
  fullLength,
  isLoading,
  data,
  selectedRow,
  onChange,
  onChangeHeader,
  handleChangeCcCard,
  handleDeleteCard,
  downloadCSV,
  rowsPerPage,
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [sortConfig, setSortConfig] = useState({
    key: searchParams.get("sortKey") || "",
    direction: searchParams.get("sortDirection") || "asc",
  });
  const [searchQuery, setSearchQuery] = useState(searchParams.get("query") || "");
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) || 1
  );

  useEffect(() => {
    setSearchParams({
      sortKey: sortConfig.key,
      sortDirection: sortConfig.direction,
      query: searchQuery,
      page: currentPage,
    });
  }, [sortConfig, searchQuery, currentPage, setSearchParams]);

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); 
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const sortedData = React.useMemo(() => {
    if (!data || !sortConfig.key) return data;
    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === "asc" ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [data, sortConfig]);

  const filteredData = React.useMemo(() => {
    if (!searchQuery) return sortedData;
    return sortedData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, sortedData]);

  const paginatedData = React.useMemo(() => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredData.slice(start, end);
  }, [filteredData, currentPage, rowsPerPage]);

  const headerData = [
    {
      name: (
        <CustomCheckbox
          onChange={(value, isChecked) => onChangeHeader(isChecked)}
          isChecked={selectedRow.length === fullLength}
        />
      ),
      width: 48,
      key: "select",
    },
    { name: t("Бин"), width: 120, key: "bin" },
    { name: t("Гео"), width: 64, key: "country_code" },
    { name: t("Дата получения"), width: 193, key: "createdAt" },
    { name: t("Вид карты"), width: 160, key: "type" },
    { name: t("Статус"), width: 320, key: "status" },
  ];

  return (
    <div data-theme={theme} className={styles["table-container"]}>
      <div className={styles["table-controls"]}>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder={t("Поиск домена")}
          className={styles["search-input"]}
        />
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            {headerData.map((el) => (
              <th
                key={el.key}
                onClick={() => el.key !== "select" && handleSort(el.key)}
                style={{ width: el.width }}
              >
                {el.name}
                {sortConfig.key === el.key && (
                  <span>{sortConfig.direction === "asc" ? " \u25B2" : " \u25BC"}</span>
                )}
              </th>
            ))}
            <th style={{ width: "144px" }}></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            Array.from({ length: rowsPerPage }).map((_, i) => (
              <TableSkeleton key={i} />
            ))
          ) : !paginatedData || !paginatedData.length ? (
            <tr>
              <td
                style={{ textAlign: "center" }}
                className={styles["not-found-td"]}
                colSpan={9}
              >
                {t("Нет данных")}
              </td>
            </tr>
          ) : (
            paginatedData.map((row, i) => {
              const createdAt = row.createdAt.split(" ");

              return (
                <tr key={row.cc}>
                  <td>
                    <CustomCheckbox
                      value={{ cc: row.cc, country_code: row.country_code }}
                      onChange={(value, isChecked) =>
                        onChange(value, isChecked)
                      }
                      isChecked={selectedRow.some((el) => el.cc === row.cc)}
                      name=""
                    />
                  </td>
                  <td>{row.bin}</td>
                  <td>
                    <div>
                      <img
                        style={{
                          width: 32,
                          height: 32,
                          marginLeft: 0,
                          borderRadius: "12px",
                        }}
                        src={`https://flagsapi.com/${row.country_code}/flat/32.png`}
                        alt={row.name}
                      />
                    </div>
                  </td>
                  <td>
                    <span>{createdAt[0]}</span>{" "}
                    <span style={{ color: "#aeaeb2" }}>{createdAt[1]}</span>
                  </td>
                  <td>{row.type}</td>
                  <td>
                    <StatusButton
                      string={row.opened ? "Открыта" : "Не открыта"}
                      status={row.opened ? "orange" : "green"}
                    />
                  </td>
                  <td>
                    <div style={{ position: "relative" }}>
                      <button onClick={() => handleChangeCcCard(row.cc)}>
                        <EditIcon
                          style={{ width: 15 }}
                          width={15}
                          height={15}
                        />
                      </button>
                      <button onClick={() => downloadCSV([row.cc])}>
                        <DwnldIcon stroke="#7B818A" />
                      </button>
                      <button onClick={() => handleDeleteCard(row.cc)}>
                        <TrashIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

