import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "ru",

    interpolation: {
      escapeValue: false,
    },

    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },

    detection: {
      order: ["navigator", "htmlTag", "localStorage", "cookie"],
      lookupLocalStorage: "i18nextLng",
      lookupCookie: "i18nextLng",
      caches: ["localStorage", "cookie"],
    },

    supportedLngs: ["ru", "en"],
    nonExplicitSupportedLngs: true,
  });

  i18n.on("missingKey", (lng, ns, key) => {
    console.warn(`Missing translation for key: ${key} in language: ${lng}`);
  });
  
export default i18n;
