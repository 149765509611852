import React, { useState, useEffect } from "react";
import { ReactComponent as EditIcon } from "../../../../assets/img/editDashbordTableIcon.svg";
import { ReactComponent as DownloadsIcon } from "../../../../assets/img/download.svg";
import styles from "./MapsTable.module.css";
import { CustomCheckbox } from "../../../../components/Checkbox/Checkbox";
import { ReactComponent as TrashIcon } from "../../../../assets/img/trashMaps.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/img/deleteIcon.svg";
import { ReactComponent as DownloadIco } from "../../../../assets/img/download.svg";
import classNames from "classnames";
import Pagination from "../../../../components/Pagination/Pagination";
import { RowPerPage } from "../../../../components/RowPerPage/RowPerPage";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import Skeleton from "./Skeleton/Skeleton";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/Button/Button";
import axiosInstance from "../../../../utils/axiosInstance";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import { getData } from "../../api";
import { toast } from "react-toastify";
import { v1 } from "uuid";
import MapsModal from "../MapsModal/MapsModal";
import NoCountry from "../../../../components/NoCountry/NoCountry";

const StatusButton = ({ string, status }) => {
  return (
    <div
      className={classNames(styles["status-btn"], {
        [styles.open]: status === "orange",
        [styles.close]: status === "green",
      })}
    >
      {string}
    </div>
  );
};

const rowsPerPageOptions = [15, 50, 100];

const MapsTable = ({
  data,
  isLoading,
  openAside,
  setRowsPerPage,
  rowsPerPage,
  currentPage,
  setCurrentPage,
  pageCountState,
  setData,
  fullLength,
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fullLenght, setFullLenght] = useState(null);
  const [isModalDowlandOpen, setIsModalDowlandOpen] = useState(false);
  const [isModalDbQOpen, setIsModalDbQOpen] = useState(false);

  useEffect(() => {
    // Get current page from URL on component mount
    const urlParams = new URLSearchParams(window.location.search);
    const savedPage = parseInt(urlParams.get("page"), 10);
    if (savedPage) {
      setCurrentPage(savedPage);
    }
  }, [setCurrentPage]);

  useEffect(() => {
    // Update URL whenever the current page changes
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", currentPage);
    window.history.pushState(null, "", `?${urlParams.toString()}`);
  }, [currentPage]);

  const handleSelectrdRowChange = (value) => {
    setSelectedRow((prev) => {
      return prev.includes(value)
        ? prev.filter((el) => el !== value)
        : [...prev, value];
    });
  };

  const handleHeaderCheckboxChange = (data, isChecked) => {
    if (isChecked) {
      const allRowNames = data.map((row) => row.cc);
      setSelectedRow(allRowNames);
    } else {
      setSelectedRow([]);
    }
  };

  const handleDownloadChecked = async (comments, format) => {
    try {
      const data = await axiosInstance({
        url: "/download-csv-without-domain",
        method: "post",
        data: {
          ccsData: selectedRow,
          format: format ? `custom|${format}` : "format4",
          comment: comments,
        },
        responseType: "blob",
      });

      if (!data) {
        console.log("No data");
      }

      const url = window.URL.createObjectURL(data.data);
      const link = document.createElement("a");
      link.href = url;

      link.download = "selected.csv";
      link.click();
      toast.success(t("Выбранные карты успешно скачаны!"));
    } catch (error) {
      toast.error(t("Ошибка при скачиванни карт!"));
      console.log(error);
    }
  };

  const handleDownloadOne = async (cc) => {
    try {
      const data = await axiosInstance({
        url: "/download-csv-without-domain",
        method: "post",
        data: {
          ccsData: [cc],
          format: "format4",
        },
        responseType: "blob",
      });

      if (!data) {
        console.log("No data");
      }

      const url = window.URL.createObjectURL(data.data);
      const link = document.createElement("a");
      link.href = url;

      link.download = `${cc}.csv`;
      link.click();
    } catch (error) {
      toast.error(t("Ошибка при скачивании карты!"));
      console.log(error);
    }
  };

  const handleDeleteCheckde = async (selectedRow) => {
    try {
      const res = await getData({
        url: "/delete-card",
        body: {
          ccsData: selectedRow,
        },
      });
      let filteredData = data.filter((item) => !selectedRow.includes(item.cc));
      setData(filteredData);
      toast.success(t("Карты успешно удалены!"));
    } catch (error) {
      toast.error(t("Ошибка при удалении карт"));
      console.log(error);
    }
  };

  const deleteData = async (cc) => {
    try {
      const res = await getData({
        url: "/delete-card",
        body: {
          ccsData: [cc],
        },
      });

      let filteredData = data.filter((item) => item.cc !== cc);
      setData(filteredData);
      toast.success(t("Карта удалена!"));
    } catch (error) {
      toast.error(t("Ошибка при удалении карты"));
      console.log(error);
    }
  };

  const getAllSelectedRows = async () => {
    try {
      const { data, full_length } = await getData({
        url: "/get-cards-by-filter",
        body: {
          domain: [],
          all: true,
        },
      });

      if (!data) {
        toast.error(t("Ошибка при выборке карт"));
        console.log("Ошибка при выборке карт");
      }
      setFullLenght(full_length);
      setSelectedRow(data.map((item) => item.cc));
    } catch (error) {
      console.log(error);
    }
  };

  const headerData = [
    {
      name: (
        <CustomCheckbox
          onChange={(value, isChecked) => {
            handleHeaderCheckboxChange(data, isChecked);
            if (isChecked) {
              getAllSelectedRows();
            }
          }}
          isChecked={selectedRow.length === fullLenght || false}
        />
      ),
      width: 48,
    },
    {
      name: t("Бин"),
      width: 120,
    },
    {
      name: t("Гео"),
      width: 64,
    },
    {
      name: t("Дата получения"),
      width: 193,
    },
    {
      name: t("Вид карты"),
      width: 160,
    },
    {
      name: t("Статус"),
      width: 320,
    },
  ];

  return (
    <>
      <div className={styles.info_container} data-theme={theme}>
        <div className={styles["all-cards"]}>
          <span>
            {t("Всего карт")}: {fullLength}
          </span>
        </div>
        <div className={styles["buttons-wrapper"]} data-theme={theme}>
          <Button
              onClick={() =>
                  selectedRow.length ? setIsModalDbQOpen(true) : null
              }
              style={{
                border: selectedRow.length > 0 ? "1px solid #191a23" : "",
                color: selectedRow.length > 0 ? "black" : "",
              }}
              className={styles["info-btn"]}
          >
            {t("Скачать по количеству")}
            <DownloadIco
                style={{
                  marginLeft: "10px",
                  stroke: selectedRow.length > 0 ? "black" : "#6a696b",
                }}
            />
          </Button>
          <Button
            onClick={() =>
              selectedRow.length ? setIsModalDowlandOpen(true) : null
            }
            style={{
              border: selectedRow.length > 0 ? "1px solid #191a23" : "",
              color: selectedRow.length > 0 ? "black" : "",
            }}
            className={styles["info-btn"]}
          >
            {t("Скачать")}
            <DownloadIco
              style={{
                marginLeft: "10px",
                stroke: selectedRow.length > 0 ? "black" : "#6a696b",
              }}
            />
          </Button>
          <Button
            onClick={() => selectedRow.length > 0 && setIsDeleteModalOpen(true)}
            className={styles["info-btn"]}
            style={{
              border: selectedRow.length > 0 ? "1px solid #191a23" : "",
              color: selectedRow.length > 0 ? "black" : "",
            }}
          >
            {t("Удалить")}
            <DeleteIcon
              style={{
                marginLeft: "10px",
                width: 13.5,
                height: 15,
                fill: selectedRow.length > 0 ? "black" : "#6a696b",
              }}
            />
          </Button>
          <DeleteModal
            isDeleteModalOpen={isDeleteModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            selectedRow={selectedRow}
            handleDelete={handleDeleteCheckde}
          />
          <MapsModal
            isModalOpen={isModalDowlandOpen}
            setIsModalOpen={setIsModalDowlandOpen}
            allDomains={selectedRow}
            downloadCSV={handleDownloadChecked}
          />
        </div>
      </div>

      <div data-theme={theme} className={styles["table-container"]}>
        <table className={styles.table}>
          <thead>
            <tr>
              {headerData.map((el, index) => (
                <th
                  key={index}
                  onClick={el.onClick}
                  style={{ width: el.width }}
                >
                  {el.name}
                </th>
              ))}
              <th style={{ width: "144px" }}></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <>
                {[...Array(rowsPerPage)].map((_, index) => (
                  <Skeleton key={index} />
                ))}
              </>
            ) : !data || data.length === 0 ? (
              <tr>
                <td className={styles["not-found-td"]} colSpan={7}>
                  {t("Нет данных")}
                </td>
              </tr>
            ) : (
              data?.map((row, index) => (
                <tr key={v1()}>
                  <td>
                    <CustomCheckbox
                      value={row.cc}
                      onChange={(value, isChecked) => {
                        handleSelectrdRowChange(value, isChecked);
                      }}
                      isChecked={selectedRow.includes(row.cc)}
                    />
                  </td>
                  <td>{row.bin}</td>
                  <td>
                    <div>
                      {row["country_code"] ? (
                        <img
                          style={{
                            width: 32,
                            height: 32,
                            marginLeft: 0,
                            borderRadius: "12px",
                          }}
                          src={`https://flagsapi.com/${row["country_code"]}/flat/32.png`}
                          alt={row["country_code"]}
                        />
                      ) : (
                        <NoCountry
                          width={35}
                          height={30}
                          style={{
                            fill: theme === "dark" ? "#fff" : "#000",
                            stroke: theme === "dark" ? "#fff" : "#000",
                          }}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <span>{row.createdAt.split(" ")[0]}</span>{" "}
                    <span className={styles["time"]}>
                      {row.createdAt.split(" ")[1]}
                    </span>
                  </td>
                  <td>{row.type}</td>
                  <td>
                    <StatusButton
                      string={row.opened ? t("Открыта") : t("Не открыта")}
                      status={row.opened ? "orange" : "green"}
                    />
                  </td>
                  <td>
                    <div>
                      <button
                        onClick={() => openAside(row.cc, true)}
                        className={styles.buttonIcon}
                      >
                        <EditIcon
                          style={{
                            width: 15,
                            stroke: row.comment > 0 ? "#8833FF" : "#7B818A",
                          }}
                          width={16}
                          height={16}
                        />
                      </button>
                      <button
                        style={{ position: "relative" }}
                        onClick={() => handleDownloadOne(row.cc)}
                        className={styles.buttonIcon}
                      >
                        <DownloadsIcon
                          style={{ stroke: "#7B818A" }}
                          width={16}
                          height={16}
                        />
                      </button>

                      <button
                        onClick={() => deleteData(row.cc)}
                        className={styles.buttonIcon}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className={styles["pagination-wrapper"]}>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={pageCountState}
        />
        <div className={styles["row-per-page-wrapper"]}>
          <RowPerPage
            options={rowsPerPageOptions}
            selectedValue={rowsPerPage}
            onChange={setRowsPerPage}
            downloadCSV={handleDownloadChecked}
          />
        </div>
      </div>
    </>
  );
};

export default MapsTable;
