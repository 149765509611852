import React, { useEffect, useRef, useState } from "react";
import { ResponsiveContainer, AreaChart, Area, Tooltip } from "recharts";
import { ReactComponent as EditIcon } from "../../../../assets/img/editDashbordTableIcon.svg";
import styles from "./DashboardTable.module.css";
import { ReactComponent as ArrowIcon } from "../../../../assets/img/upIcon.svg";
import { ReactComponent as SortIcon } from "../../../../assets/img/sortArrow.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import moment from "moment-timezone";
import { dateFormatter } from "../../../../utils/script";
import { getData } from "../../api";
import { useTranslation } from "react-i18next";
import SubmenuDots from "../../../../components/SubmenuDots/SubmenuDots";
import { SubMenuDashboard } from "../../../../components/SubMenuDashboard/SubMenuDashboard";
import { SkeletonTable } from "./SkeletonTable";
import classNames from "classnames";
import { useUserData } from "../../../../context/UserProvider";
import { NavLink } from 'react-router-dom';
import axios from 'axios';
const CustomTooltip = ({ active, payload, label, coordinate }) => {
  if (active && payload && payload.length) {
    const tooltipStyle = {
      width: "150px",
      position: "absolute",
      top: coordinate.y - 70,
      left: coordinate.x - 30,
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      padding: "10px",
      borderRadius: "5px",
      pointerEvents: "none",
      zIndex: 1000,
      color: "black",
    };

    return (
      <div style={tooltipStyle}>
        <p>{`Date: ${payload[0].payload.time}`}</p>
        <p>{`Count: ${payload[0].payload.value}`}</p>
      </div>
    );
  }

  return null;
};

const LineChartCell = ({ data }) => (
  <ResponsiveContainer width="100%" height={50}>
    <AreaChart data={data}>
      <defs>
        <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="rgba(188, 44, 255, 0.5)" />
          <stop offset="100%" stopColor="rgba(206, 17, 203, 0)" />
        </linearGradient>
      </defs>
      <Tooltip content={CustomTooltip} />
      <Area
        type="linear"
        dataKey="value"
        stroke="rgb(124, 60, 140)"
        fill="url(#colorGradient)"
      />
    </AreaChart>
  </ResponsiveContainer>
);

const TableHeader = ({ el, data, setData, isLast, updateUrlState, setSortOptions }) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleColumnSort = (key, order) => {
    setIsHovered(false);
    setSortOptions((prev) => ({ ...prev, [key]: order }));
    updateUrlState({ sortKey: key, sortOrder: order });
  };

  const handleHeaderClick = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <th
      ref={dropdownRef}
      style={{ minWidth: el.width, width: el.width }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={el.sort && handleHeaderClick}
    >
      <div className={styles["table-header"]}>
        {el.name}

        {el.sort && isHovered && (
          <div style={{ minWidth: "20px" }} className={styles["arrow-header"]}>
            <ArrowIcon style={{ width: "10px" }} />
            <ArrowIcon style={{ width: "10px", transform: "rotate(180deg)" }} />
          </div>
        )}

        {isMenuOpen && (
          <div
            className={classNames(styles["dropdown-menu"], {
              [styles["dropdown-menu-last"]]: isLast,
            })}
          >
            <div onClick={() => handleColumnSort(el.sort, "asc")}> 
              <SortIcon style={{ stroke: "#aeaeb2" }} /> {t("По возрастанию")}
            </div>
            <div onClick={() => handleColumnSort(el.sort, "desc")}> 
              <SortIcon
                style={{ transform: "rotate(180deg)", stroke: "#aeaeb2" }}
              /> {t("По убыванию")}
            </div>
          </div>
        )}
      </div>
    </th>
  );
};

export const DashboardTable = ({ data, setData, openAside, setDefaultData, isLoading,sortOptions, setSortOptions }) => {
  const { theme } = useTheme();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [chartData, setChartData] = useState({});
  const [openDotsMenu, setOpenDotsMenu] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { userData } = useUserData();

  const updateUrlState = (state) => {
    const searchParams = new URLSearchParams(location.search);

    Object.entries(state).forEach(([key, value]) => {
      if (value !== undefined) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });

    navigate({ search: searchParams.toString() }, { replace: true });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sortKey = searchParams.get("sortKey");
    const sortOrder = searchParams.get("sortOrder");

    if (sortKey && sortOrder) {
      setSortOptions({ [sortKey]: sortOrder });
    }
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      const body = {
        queryData: 1,
        count: 50,
        ...sortOptions,
      };
      const {data} = await getData({
        url: "/get-my-domains",
        body,
      });
  
      let sortedData = Array.isArray(data) ? data : []
      if(Array.isArray(data) && sortOptions){
        // if(sortOptions){
        //   if(sortOptions["byLast"]){
        //     sortedData = data.sort((a,b) => {
        //       if(sortOptions["byLast"] === "asc"){
        //         return new Date(b.last) - new Date(a.last)
        //       }else{
        //         return new Date(a.last) - new Date(b.last)
        //       }
        //     })
        //   }
        // }
        // if(sortOptions){
        //   if(sortOptions["byDate"]){
        //     sortedData = data.sort((a,b) => {
        //       if(sortOptions["byDate"] === "asc"){
        //         return new Date(b.createdAt) - new Date(a.createdAt)
        //       }else{
        //         return new Date(a.createdAt) - new Date(b.createdAt)
        //       }
        //     })
        //   }
        // }
        // if(sortOptions){
        //   if(sortOptions["byTotal"]){
        //     sortedData = data.sort((a,b) => {
        //       if(sortOptions["byTotal"] === "asc"){
        //         return b.total - a.total
        //       }else{
        //         return a.total - b.total
        //       }
        //     })
        //   }
        // }
        // if(sortOptions){
        //   if(sortOptions["byDuplicates"]){
        //     sortedData = data.sort((a,b) => {
        //       if(sortOptions["byDuplicates"] === "asc"){
        //         return b.double - a.double
        //       }else{
        //         return a.double - b.double
        //       }
        //     })
        //   }
        // }
      }
      setData(sortedData);
    };
  
    fetchData();
  }, [sortOptions]);
  



  const handleOpenDotsMenu = (index) => {
    if (openDotsMenu === index) {
      setOpenDotsMenu(null);
    } else {
      setOpenDotsMenu(index);
    }
  };

  const handleAddArchive = async (domain) => {
    const result = await getData({
      url: "/archive-domain",
    });
    if (result === "Domain successfully archived") {
      setDefaultData((prev) => {
        return prev.map((el) =>
          el.domain_name === domain ? { ...el, archived: 1 } : el
        );
      });
    }
  };

  const handleUnArchive = async (domain) => {
    const result = await getData({
      url: "/unarchive-domain",
      body: { domain: domain },
    });

    if (result === "Domain successfully unarchived") {
      setDefaultData((prev) => {
        return prev.map((el) =>
          el.domain_name === domain ? { ...el, archived: 0 } : el
        );
      });
    }
  };

  const handleRemoveDomain = async (domain) => {
    const result = await getData({
      url: "/delete-accepted-domain",
      body: { domain: domain },
    });

    if (result) {
      setDefaultData((prev) => {
        return prev.filter((el) => el.domain_name !== domain);
      });
    }
  };

  const headerData = [
    {
      name: t("URL"),
      width: 168,
    },
    {
      name: t("Дата появления"),
      width: 110,
      sort: "byDate",
    },
    {
      name: t("Последняя карта"),
      width: 110,
      sort: "byLast",
    },
    {
      name: t("В наличии / всего"),
      width: 110,
      sort: "byTotal",
    },
    {
      name: t("График"),
      width: 192,
    },
    {
      name: t("Карты"),
      width: 90,
      sort: "byCards",
    },
    {
      name: t("Уникал"),
      width: 90,
      sort: "byTotal",
    },
    {
      name: t("Дубли"),
      width: 60,
      sort: "byDuplicates",
    },
  ];

  useEffect(() => {
    const loadData = async () => {
      if (!Array.isArray(data)) return;

      const dataPromises = data.map(async (row) => {
        const result = await getData({
          url: "/filter-by-one-week",
          body: { domain: row.domain_name, date: "month" },
        });

        const formattedData = Object.keys(result)
          .filter((key) => key !== "total")
          .map((date) => ({
            value: result[date],
            time: date,
          }))
          .sort((a, b) => new Date(a.time) - new Date(b.time)); // Sorting the chart data by date

        return { domain_name: row.domain_name, chartData: formattedData };
      });

      const results = await Promise.all(dataPromises);

      const chartDataMap = [];

      results.forEach(({ domain_name, chartData }) => {
        chartDataMap[domain_name] = chartData;
      });

      setChartData(chartDataMap);
    };

    loadData();
  }, [data]);

  return (
    <div
      style={{
        overflowY: data.length <= 5 ? "auto" : "hidden",
      }}
      data-theme={theme}
      className={styles["table-container"]}
    >
      <table className={styles.table}>
        <thead>
          <tr>
            {headerData.map((el, i) => {
              return (
                <TableHeader
                  isLast={i + 1 === headerData.length}
                  key={el.name}
                  el={el}
                  data={data}
                  setData={setData}
                  updateUrlState={updateUrlState}
                  setSortOptions={setSortOptions}
                />
              );
            })}
            <th style={{ width: 90 }}></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            Array.from({ length: 10 }).map((_, i) => <SkeletonTable key={i} />)
          ) : !data || !data.length ? (
            <tr>
              <td
                style={{ textAlign: "center" }}
                className={styles["not-found-td"]}
                colSpan={9}
              >
                {t("Нет данных")}
              </td>
            </tr>
          ) : (
            data.slice(0, rowsPerPage).map((row, index) => {
              const createdAt = row.createdAt.split(" ");

              return (
                <tr key={row.id}>

<td style={{ cursor: "pointer" }}>
  {userData?.role > 2 
  ?
    <NavLink to={`/dashboard/${row.domain_name}`} end>
      {row.domain_name}
    </NavLink>
  :
    <p style={{color: "var(--toastify-color-info)"}}>{row.domain_name}</p>
  }
</td>
                  <td>
                    <p style={{ fontSize: "13px" }}>{createdAt[0]}</p>
                    <p style={{ color: "#aeaeb2" }}>{createdAt[1]}</p>
                  </td>
                  <td>
                    <span style={{ color: DeadlineIcon(row.last) }}>
                      {moment().diff(moment(row.last), "months") > 6
                        ? moment(row.last).format("DD-MM-YYYY")
                        : moment(row.last).locale(i18n.language).fromNow()}
                    </span>
                  </td>
                  <td>
                    {row.not_opened_count < 0 ? "0" : row.not_opened_count}/
                    {Math.abs(row.total - row.double)}
                  </td>
                  <td className={styles["chart-row"]}>
                    {chartData[row.domain_name]?.length > 4 ? (
                      <LineChartCell data={chartData[row.domain_name]} />
                    ) : (
                      <span>{t("Небольшой набор данных")}</span>
                    )}
                  </td>
                  <td>{row.total}</td>
                  <td>{Math.abs(row.total - row.double)}</td>
                  <td>{row.double}</td>
                  <td>
                    <div
                      className={styles["wrapper-button"]}
                      style={{ position: "relative" }}
                    >
                      {userData.role > 3 && (
                        <button
                          onClick={() => openAside(row.domain_name, true)}
                        >
                          <EditIcon
                            style={{
                              width: 15,
                            }}
                            stroke={row.comment ? "#8833FF" : "#7B818A"}
                            width={15}
                            height={15}
                          />
                        </button>
                      )}

                      {userData.role > 5 && (
                        <>
                          <SubmenuDots
                            setIsOpen={setOpenDotsMenu}
                            isOpen={openDotsMenu === row.id}
                          >
                            <SubMenuDashboard
                              data={row}
                              isOpen={openDotsMenu === row.id}
                              isArchived={row.archived === 1}
                              onUnArchive={() =>
                                handleUnArchive(row.domain_name)
                              }
                              onArchive={() =>
                                handleAddArchive(row.domain_name)
                              }
                              onRemove={() => {
                                handleRemoveDomain(row.domain_name);
                                setOpenDotsMenu(null);
                              }}
                            />
                          </SubmenuDots>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

const DeadlineIcon = (deadline) => {
  const today = moment();
  const dueDate = moment(dateFormatter(deadline));

  const daysLeft = dueDate.diff(today, "days");

  let color;
  if (daysLeft >= -2) {
    color = "#00e200"; // Меньше 2 дней (зеленый)
  } else if (daysLeft <= -3 && daysLeft >= -14) {
    color = "#f3bb1a"; // Меньше 2 недель (желтый)
  } else {
    color = "#f13636"; // Больше 2 недель (красный)
  }

  return color;
};